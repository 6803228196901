import React from 'react';
import styles from './App.modules.css';

export default function App() {
  return (
    <div>
      <h1>Under Construction...</h1>
      <a href="https://www.linkedin.com/in/colin-bassett/"><h3>LinkedIn</h3></a>
    </div>
  );
}
